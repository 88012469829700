function FilledFactSheet({data}) {
    
    function getCurrentDate() {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = currentDate.getFullYear();
      
        return `${month}/${day}/${year}`;
    }

    function getStudentSignDate() {
        const studentSignDateString = data.studentSignDate;
        const studentSignDate = new Date(studentSignDateString);
    
        const day = String(studentSignDate.getDate()).padStart(2, '0');
        const month = String(studentSignDate.getMonth() + 1).padStart(2, '0');
        const year = studentSignDate.getFullYear();
      
        return `${month}/${day}/${year}`;
    }

    function getStudentInitials(fullName) {
        const words = fullName.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        return initials;
    }

    function getLastYear() {
        const currentDate = new Date();
        const lastYear = currentDate.getFullYear() - 1;
        return lastYear;
    }

    function getYearBeforeLast() {
        const currentDate = new Date();
        const yearBeforeLast = currentDate.getFullYear() - 2;
        return yearBeforeLast;
    }

    const calculatePercentage = (studentsCompleter, studentsBegan) => {
        const result = (studentsCompleter / studentsBegan) * 100;
        return isNaN(result) ? "N/A" : `${result.toFixed(2)} %`;
    };
      
    return (
    <div className="container">
        <img src="https://storage.googleapis.com/lsi-public/logo-bw.png" width={200}/>
        <div className="headerContainer">
            <div className="campusInfo">
                <h1>LANGUAGE SYSTEMS INTERNATIONAL</h1>
                <h1>{data.campus.campusName} CAMPUS</h1>
                <h2>{data.campus.address}</h2>
                <h2>{data.campus.phone} {data.campus.site}</h2>
            </div>
        </div>
        <div className="factSheetHeader">
            <h1 className="calendarYears">School Performance Fact sheet Calendar Years {getYearBeforeLast()} & {getLastYear()} </h1>
            <h1>{data.program.levelName} - ({data.program.timeToComplete} Hours to Complete)</h1>
        </div>
        <div className="tableContainer">
            <div className="titleLabel">
                <h2><u>On-time Completion Rates (Graduation Rates)</u></h2>
                <h3><i>Includes data for the Mo calendar years prior to reporting.</i></h3>
            </div>
            <table className="dataTables">
                <tr>
                    <th>Calendar Year</th>
                    <th>Number of Students Who Began the Program</th>
                    <th>Students Available for Graduation</th>
                    <th>Number of On-time Graduates</th>
                    <th>On-time Completion Rate</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>{data.program.studentsBeganLast2Year}</td>
                    <td>{data.program.studentsBeganLast2Year}</td>
                    <td>{data.program.completers100Last2Year}</td>
                    <td>{calculatePercentage(data.program.completers100Last2Year, data.program.studentsBeganLast2Year)}</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>{data.program.studentsBeganLastYear}</td>
                    <td>{data.program.studentsBeganLastYear}</td>
                    <td>{data.program.completers100LastYear}</td>
                    <td>{calculatePercentage(data.program.completers100LastYear, data.program.studentsBeganLastYear)}</td>
                </tr>
            </table>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong>
                <span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
        </div>
        <div className="tableContainer">
            <div className="titleLabel" style={{marginTop: "4.5rem"}}>
                <h2><u>Students Completing Within 150% of the Published Program Length</u></h2>
            </div>
            <table className="dataTables">
                <tr>
                    <th>Calendar Year</th>
                    <th>Number of Students Who Began the Program</th>
                    <th>Students Available for Graduation</th>
                    <th>150% Graduates</th>
                    <th>150% Completion Rate</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>{data.program.studentsBeganLast2Year}</td>
                    <td>{data.program.studentsBeganLast2Year}</td>
                    <td>{data.program.completers150Last2Year}</td>
                    <td>{calculatePercentage(data.program.completers150Last2Year, data.program.studentsBeganLast2Year)}</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>{data.program.studentsBeganLastYear}</td>
                    <td>{data.program.studentsBeganLastYear}</td>
                    <td>{data.program.completers150LastYear}</td>
                    <td>{calculatePercentage(data.program.completers150LastYear, data.program.studentsBeganLastYear)}</td>
                </tr>
            </table>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
        </div>
        <div className="tableContainer">
            <div className="titleLabel">
                <h2><u>Job Placement Rates (includes data for the two calendar years prior to reporting)</u></h2>
            </div>
            <table className="dataTables">
                <tr>
                    <th>Calendar Year</th>
                    <th>Number of Students Who Began the Program</th>
                    <th>Number of Graduates</th>
                    <th>Graduates Available for Employment</th>
                    <th>Graduates Employed in the Field</th>
                    <th>Placement Rate Employed in the field</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>{data.program.studentsBeganLast2Year}</td>
                    <td>{data.program.completers150Last2Year + data.program.completers100Last2Year}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>{data.program.studentsBeganLastYear}</td>
                    <td>{data.program.completers150LastYear + data.program.completers100LastYear}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
        </div>
        <div className="tableContainer">
            <div className="titleLabel" style={{marginTop: "9rem"}}>
                <h2 className="gainfulEmployment"><u>Gainful Employment Categories (includes data for ffie hyo calendar years prior fa reporting)</u></h2>
            </div>
            <h2 className="subtitleEmployment"><u>Part-Time vs Full-Time Employment</u></h2>
            <table className="dataTables">
                <tr>
                    <th>Calendar Year</th>
                    <th>Graduated Employed in the field 20 to 29 hours per week</th>
                    <th>Graduated Employed in the field at least 30 hours per week</th>
                    <th>Total Graduates Employed in the Field</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table>
        </div>
        <div className="tableContainer">
            <h2 className="subtitleEmployment"><u>Single Position vs. Concurrent Aggregated Positions</u></h2>
            <table className="dataTables">
                <tr>
                    <th>Calendar Year</th>
                    <th>Graduated Employed in the field in a single position</th>
                    <th>Graduated Employed in concurrent aggregated positions</th>
                    <th>Total Graduates Employed in the Field</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table>
        </div>
        <div className="tableContainer">
            <h2 className="subtitleEmployment"><u>Self-Employed/Freelance Positions</u></h2>
            <table className="dataTables">
                <tr>
                    <th>Calendar Year</th>
                    <th>Graduated Employed who are Self-Employed or Working Freelance</th>
                    <th>Total Graduates Employed in the Field</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table>
        </div>
        <div className="tableContainer">
            <h2 className="subtitleEmployment" style={{marginTop: "10rem"}}><u>Institutional Employment</u></h2>
            <table className="dataTables">
                <tr>
                    <th>Calendar Year</th>
                    <th>Graduates Employed in the Field who are Employed by the Institution, an Employer Owned by the Institution, or an Employer who Shares Ownership with the Institution</th>
                    <th>Total Graduates Employed in the Field</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
        </div>
        <div className="tableContainer">
            <div className="titleLabel">
                <h2><u>License Examination Passaqe Rates (includes data for the two calendar years prior to reporting)</u></h2>
            </div>
            <table className="dataTables">
                <tr>
                    <th>First Available Exam Date</th>
                    <th>Date Exam Results Announced</th>
                    <th>Number of Graduates in Calendar Year</th>
                    <th>Number of Graduates Taking Exam</th>
                    <th>Number Who Passed Exam</th>
                    <th>Number Who Failed Exam</th>
                    <th>Passage Rate</th>
                </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>{data.program.completers150Last2Year + data.program.completers100Last2Year}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>{data.program.completers150LastYear + data.program.completers100LastYear}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
        </div>
        <div className="tableContainer">
            <div className="titleLabel" style={{marginTop: "8rem"}}>
                <h2><u>Salary and Wage Information (includes data for the two calendar years prior to reportinq)</u></h2>
            </div>
            <table className="dataTables">
                <tr>
                    <th rowSpan={2}>Calendar Year</th>
                    <th rowSpan={2}>Graduates Available for Employment</th>
                    <th rowSpan={2}>Graduates Employed in Field</th>
                    <th colSpan={5} rowSpan={1}>Annual Salary and Wages Reported Graduates Employed in the field</th>
                </tr>
                <tr>
                    <th>$15,000 to $20,000</th>
                    <th>$20,001 to $25,000</th>
                    <th>$25,001 to $30,000</th>
                    <th>$30,001 to $35,000</th>
                    <th>Students not Reporting Salary</th>
                </tr>
                <tr>
                    <td>{getYearBeforeLast()}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>{getLastYear()}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </table>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
        </div>
        <div>
            <div className="titleLabel">
                <h2><u>Cost of Educational Program</u></h2>
            </div>
            <p className="paragraphs">Total charges for the program for students completing on-time in {getYearBeforeLast()}: $12,942. Total charges may be higher for students that do not complete on time.</p>
            <p className="paragraphs">Total charges for the program for students completing on time in {getLastYear()}: $12,942. Total charges may be higher for students that do not complete on time.</p>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
        </div>
        <div>
            <div className="titleLabel">
                <h2><u>Federal Student Loan Debt</u></h2>
                <p className="paragraphs">Students at LANGUAGE SYSTEMS INTERNATIONAL are not eligible for federal student loans. This institution does not meet the U.S. Department of Education criteria that would allow its 
                    students to participate in federal student aid programs.
                </p>
            </div>
            <div className="initialsLabel">
                <strong>Student's Initials: </strong>
                <button className="signatureLabel">
                    {getStudentInitials(data.studentInfo.studentName)}
                </button>
                <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                <p>Initial only after you have had sufficient time to read and understand the information.</p>
            </div>
            <p className="paragraphs">This fact sheet is filed with the Bureau for Private Postsecondary Education. Regardless of any information you may have relating to completion rates, placement rates, starting salaries, 
                or license exam passage rates, this fact sheet contains the infomalion as calculated pursuant to state!aw.
            </p>
            <p className="paragraphs">Any questions a student may have regarding this fact sheet that have not been satisfactorily answered by the institution may be directed to the Bureau for Private Postsecondary Education at 2535 Capitol Oaks Drive, Suite 400, Sacramento, 
            CA 95833, www.bppe.ca.gov, toll-free telephone number (888) 370-7589 or by fax (916) 263-1897.
            </p>
            <div className="signatureContainer">
                <div className="studentSigName">
                    <strong>Student Name - Print: </strong><span>{data.studentInfo.studentName}</span>
                </div>
                <div className="studentSignatures">
                    
                    <strong>Student Signature: </strong>
                    <button className="signatureLabel">
                        {data.studentInfo.studentName}
                    </button>
                    <strong className="dateLabel">Date: </strong><span>{ !data.studentSignDate ? getCurrentDate() : getStudentSignDate() }</span>
                </div>
                <div className="studentSignatures">
                    <strong>School Official </strong>
                    <button className="signatureLabel">
                        {data.schoolOfficialName}
                    </button>
                    <strong className="dateLabel">Date: </strong><span>{ !data.schoolOfficialName ? '' : getCurrentDate() }</span>
                </div>
            </div>
        </div>
        <div className="factSheetInfo">
            <ul>
                <li>"Number of Students Who Began the Program" means the number of students who began a program who were scheduled to complete the program within 100% of the published program length within the reporting calendar year and excludes all students who cancelled during the cancellation period.</li>
                <li>"Students Available for Graduation" is the number of students who began the program minus the number of students who have died, been incarcerated, or been called to active military duty.</li>
                <li>"Number of On-time Graduates" is the number of students who completed the program within 100% of the published program length within the reporting calendar year.</li>
                <li>"On-time Completion Rate" is the number of on-time graduates divided by the number of students available for graduation.</li>
                <li>"150% Graduates" is the number of students who completed the program within 150% of the program length (includes on-time graduates).</li>
                <li>"150% Completion Rate" is the number of students who completed the program in the reported calendar year within 150% of the published program length, including on-time graduates, divided by the number of students available for graduation.</li>
                <li>"Graduates Available for Employment" means the number of graduates minus the number of graduates unavailable for employment.</li>
                <li>"Graduates Unavailable for Employment" means the graduates who, after graduation, die, become incarcerated, are called to active military duty, are international students that leave the United States or do not have a visa allowing employment in the United States, or are continuing their education in an accredited or bureau-approved program.</li>
                <li>“Graduates Employed in the Field” means graduates who beginning within six months after a student completes the applicable educational program are gainfully employed, whose employment has been reported, and for whom the institution has documented verification of employment. For occupations for which the state requires passing an examination, the six months period begins after the announcement of the examination results for the first examination available after a student completes an applicable educational program.</li>
                <li>“Placement Rate Employed in the Field” is calculated by dividing the number of graduates gainfully employed in the field by the number of graduates available for employment.</li>
                <li>“Number of Graduates Taking Exam” is the number of graduates who took the first available exam in the reported calendar year.</li>
                <li>“First Available Exam Date” is the date for the first available exam after a student completed a program.</li>
                <li>“Passage Rate" is calculated by dividing the number of graduates who passed the exam by the number of graduates who took the reported licensing exam.</li>
                <li>"Number Who Passed First Available Exam” is the number of graduates who took and passed the first available licensing exam after completing the program.</li>
                <li>"Salary" is as reported by graduate or graduate's employer.</li>
                <li>“No Salary Information Reported" is the number of graduates for whom, after making reasonable attempts, the school</li>
            </ul>
        </div>
        <div className="studentRights">
            <h1>STUDENT'S RIGHT TO CANCEL TO ACCOMPANY THE {getYearBeforeLast()} - {getLastYear()} SCHOOL PERFORMANCE FACT SHEET</h1>
            <h2>WITHDRAWAL FROM COURSE:</h2>
            <p>You have the right to withdraw from a course of instruction at any time. A withdrawal can take place either by written notice provided by you to the school you are attending or by your conduct, including but not necessarily limited to, your lack of attendance.</p>
            
            <h2>STUDENT'S RIGHT TO CANCEL:</h2>
            <p>You have the right to cancel this enrollment and obtain a remind of charges paid through attendance at the first class session, or the seventh day after enrollment date {getStudentSignDate()} , whichever is later. "Cancellation" is defined as: any voluntary change in enrollment or request for refund prior to enrollment. You must indicate your request to cancel and obtain a refund by notifying the Language Systems Campus listed on your 1-20, or a Pre-Enrollment Application, or the campus at which you applied in person.</p>

            <h2>REFUND POLICY:</h2>
            <p>You shall be refunded 100% of the amount paid for institutional charges, less both a $150 non-refundable processing fee and the $100 shipping and handling fee (if Language Systems processed the mailing of your 1- 20), if notice of cancellation is made through attendance at the first class session or the seventh day after enrollment, whichever is later.</p>
            <p>If after the above cancellation deadline, the refund policy for students who have completed 60 percent or less of the period of attendance shall be a pro rata refund calculated from the last day of attendance, less a $150 non-refundable processing fee, the $100 shipping and handling See (if applicable), and the STRF fee.</p>
            <p>Under California law, if the student withdraws after using more than 60% of the class hours paid for, no refund will be issued. Transportation and activities fees are not subject to this 60% limitation.</p>
            <p>60% of your class hours paid will be on</p>
            <p>All refunds as determined above will be paid within 45 days after the date of your completion of or withdrawal from Language Systems' program, according to Language Systems' Cancellation or Drop Out/Withdrawal Policy.</p>
            <p>If your enrollment was facilitated by an authorized Language Systems overseas agent, the recipient of any applicable refund may be governed by the contractual agreement between Language Systems and the agent. You are advised to consult with your agent prior to entering into this enrollment agreement should you have any questions.</p>
        </div>
    </div>
    );
  }
  
  export default FilledFactSheet;